@import 'variable.scss';
html {
  	height: 100%;
}
body{
	font-family: 'Avenir' !important;
	padding: 0;
	margin:0;
	box-sizing: border-box;
	font-weight: 400;
	height: 100%;
	font-size: 14px;
	line-height: 1.5;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	overflow-x: hidden;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: $white !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul{
	margin: 0;
	padding: 0;
	line-height: normal;
	list-style-type: none;
	text-decoration: none !important;
}
a{
	transition: all 0.3s ease-in-out;
	&:hover{
		opacity: 0.8;
	}
}
.vadililayout{
	min-height: 700px;
}
// common css file //
.validi-back-btn-wrapper{
	margin-bottom: 30px;
	a{
		font-size: 16px;
		font-weight: 600;
		color: #9E9E9E;
		display: inline-flex;
		align-items: center;
		transition: all 0.3s ease-in-out;
		i{
			width: 18px;
			height: 18px;
			display: flex;
			margin-right: 15px;
			svg{
				width: 100%;
				height: 100%;
				display: block;
			}
		}
		&:hover{
			color: $green;
			i{
				svg{
					g{
						fill: $green;
					}
				}
			}
		}
	}
}
.validi-theme-btn{
	min-width: 150px;
    padding: 9px 18px;
    outline: none;
    box-shadow: none;
    font-size: 15px;
    color: $white;
    background: $green;
    border-radius: 4px;
    font-weight: 500;
    border: none;
	border: 1px solid $green;
	transition: all 0.3s ease-in-out;
	&:hover{
		opacity: 0.8;
	}
	&.clear-btn{
		background: transparent;
		color: #FF0000;
		text-decoration: underline !important;
		border: none;
		min-width: 70px;
		padding: 0;
		margin: 0;
		margin-left: 15px;
		font-style: italic;
	}
}
.main-title{
	font-size: 27px;
	font-weight: 800;
	color: $black;
	margin-bottom: 20px;
}
.validi-user-main-title{
	font-size: 18px;
	color: $black;
	font-weight: 800;
	margin-bottom: 6px;
}
.validi-subtitle{
	font-size: 15px;
	color: $black;
	font-weight: 800;
	margin-bottom: 6px;
}
.validi-paragraph{
	font-size: 14px;
	color: $lightgrey;
	font-weight: normal;
	margin-bottom: 0px;
	line-height: normal;
}
.validi-sub-paragraph{
	font-size: 15px;
	color: $lightgrey;
	font-weight: normal;
    margin-bottom: 12px;
	font-style: italic;
}
.validi-avatar-details-wrepper{
	display: flex;
	align-items: center;
	.validi-avatar-img{
		width: 50px;
		height: 50px;
		overflow: hidden;
		border-radius: 1000px;
		box-shadow: 0 0 4px 0 rgba(0,0,0,0.15);
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
	.validi-avatar-details{
		margin-left: 15px;
	}
} 
.validi-inner-header-bar{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px; 
	@include media-breakpoint-down(md){
		flex-direction: column;
		align-items: start;
	}
	.validi-select-input{
		width: 250px;
		max-width: 100%;
		@include media-breakpoint-down(md){
			width: 100%; 
			padding-top: 15px;
			border-top: 1px solid $border-color;
			margin-top: 15px;
		}
		.css-2b097c-container{
			width: 100% !important;
			.css-yk16xz-control{
				background: transparent;
				border: none;
				border: 1px solid $border-color;
				box-shadow: none;
				outline: none;
				padding: 4px;
				> div {
					.css-1wa3eu0-placeholder{
						font-size: 14px;
						font-weight: 800;
						color: $black;
					}
					.css-1uccc91-singleValue{
						font-size: 14px;
						font-weight: 800;
						color: $black;
					}
				}
				.css-1hb7zxy-IndicatorsContainer{
					.css-1okebmr-indicatorSeparator{
						display: none;
					}
					.css-tlfecz-indicatorContainer{
						color: $black;
					}
				}
			}
			.css-1pahdxg-control{
				background: transparent;
				border: none;
				border: 1px solid $border-color;
				box-shadow: none;
				outline: none;
				padding: 4px;
				> div {
					.css-1wa3eu0-placeholder{
						font-size: 14px;
						font-weight: 800;
						color: $black;
					}
					.css-1uccc91-singleValue{
						font-size: 14px;
						font-weight: 800;
						color: $black;
					}
				}
				.css-1hb7zxy-IndicatorsContainer{
					.css-1okebmr-indicatorSeparator{
						display: none;
					}
					.css-tlfecz-indicatorContainer{
						color: $black;
					}
				}
			}
			.css-26l3qy-menu{
				background: #23272c;
				z-index: 2;
				.css-4ljt47-MenuList{
					.css-9gakcf-option{
						background: $green;
						cursor: pointer;
					}
					.css-1n7v3ny-option{
						background: $green;
						cursor: pointer;
					}
				}
			}
		}
	}     
}
.validi-radio-btnbox{
	.radio-btn {
		display: inline-flex;
		position: relative;
		padding-left: 35px;
		cursor: pointer;
		font-size: 15px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		color: $lightgrey;
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			&:checked ~ .checkmark {
				background-color: transparent;
				border-color: $green;
				&:after {
					display: block;
					background: $green;
				}
			}
			 
		}
		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			margin: auto;
			height: 30px;
			width: 30px;
			border-radius: 100px;
			border: 1px solid $lightgrey;
			background: transparent;
			&:after {
				content: "";
				position: absolute;
				display: none;
				top: 0;
				left: 0;
				margin: auto;
				right: 0;
				bottom: 0;
				width: 16px;
				height: 16px;
				border-radius: 100px;
				background: $lightgrey;
			}
			&::before{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				margin: auto;
				right: 0;
				bottom: 0;
				width: 16px;
				height: 16px;
				border-radius: 100px;
				background: $lightgrey;
			}
		}
	}
}
form{
	.form-group{
		margin-bottom: 18px;
		label{
			font-weight: 500;
			font-size: 14px;
			color: $lightgrey;
			margin-bottom: 5px;
		}
		.form-control{
			border: 1px solid $border-color;
			border-radius: 3px;
			padding: 12px;
			background: $white;
			outline: none;
			box-shadow: none;
			font-size: 14px;
			color: $black;
			font-weight: 600;
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: $lightgrey;
				font-weight: normal;
			}
			&::-moz-placeholder { /* Firefox 19+ */
				color: $lightgrey;
				font-weight: normal;
			}
			&:-ms-input-placeholder { /* IE 10+ */
				color: $lightgrey;
				font-weight: normal;
			}
			&:-moz-placeholder { /* Firefox 18- */
				color: $lightgrey;
				font-weight: normal;
			}
		}
		.form-select{
			padding: 11px !important;		
		}
		textarea{
			&.form-control{
				resize: none;
				height: 100px;
			}
		}
		.css-2b097c-container{
			width: 100% !important;
			.css-yk16xz-control{
				background: transparent;
				border: none;
				border: 1px solid $border-color;
				box-shadow: none;
				outline: none;
				> div {
					padding: 5px 8px;
					.css-1wa3eu0-placeholder{
						font-size: 14px;
						font-weight: 800;
						color: $black;
					}
					.css-1uccc91-singleValue{
						font-size: 14px;
						font-weight: 800;
						color: $black;
					}
				}
				.css-1hb7zxy-IndicatorsContainer{
					.css-1okebmr-indicatorSeparator{
						display: none;
					}
					.css-tlfecz-indicatorContainer{
						color: $black;
					}
				}
			}
			.css-1pahdxg-control{
				background: transparent;
				border: none;
				border: 1px solid $border-color;
				box-shadow: none;
				outline: none;
				> div {
					padding: 5px 8px;
					.css-1wa3eu0-placeholder{
						font-size: 14px;
						font-weight: 800;
						color: $black;
					}
					.css-1uccc91-singleValue{
						font-size: 14px;
						font-weight: 800;
						color: $black;
					}
				}
				.css-1hb7zxy-IndicatorsContainer{
					.css-1okebmr-indicatorSeparator{
						display: none;
					}
					.css-tlfecz-indicatorContainer{
						color: $black;
						padding: 0;
					}
				}
			}
			.css-26l3qy-menu{
				background: #23272c;
				z-index: 2;
				.css-4ljt47-MenuList{
					.css-9gakcf-option{
						background: $green;
						cursor: pointer;
					}
					.css-1n7v3ny-option{
						background: $green;
						cursor: pointer;
					}
				}
			}
		}
	}
	.validi-theme-btn{
		margin-top: 10px;
	}
}
.validi-tabbar-wrapper{
	.nav{
		margin-bottom: 15px;
		border: none;
		.nav-item{
			.nav-link {
				font-size: 14px;
				font-weight: 500;
				min-width: 95px;
				padding: 10px 12px;
				color: $green;
				background: $white;
				border: 1px solid $green;
				margin-right: 15px;
				border-radius: 3px;
				transition: all 0.3s ease-in-out;
				&.active{
					background: $green;
					color: $white;
				}
				&:hover{
					background: $green;
					color: $white;
				}
			}
		}
	}
	.tab-content{
		padding: 25px;
		border: 1px solid $border-color;
		.validi-journals-block{
			h3{
				font-size: 21px;
				font-weight: 800;
				color: $black;
				margin-bottom: 15px;
			}
			.validi-journal-msg{
				border-bottom: 1px solid $border-color;
				margin-bottom: 20px;
				&:last-child{
					margin-bottom: 0;
					border-bottom: none;
				}
				.validi-journal-user-detail{
					padding: 8px 0 15px;
				}
			}
		}
		.validi-nodata-found{
			background: $userbg-color;
			border: 1px solid $border-color;
			border-radius: 3px;
			width: 100%;
			height: 220px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 15px;
			h2{
				font-size: 18px;
				color: $lightgrey;
				font-style: italic;
				font-weight: normal;
				margin-bottom: 0;
			}
		}
	}
}
.validi-tabbar-inner-header{
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid $border-color;
	padding-bottom: 18px;
	margin-bottom: 20px;
	p{
		font-size: 14px;
		color: $lightgrey;
		font-weight: normal;
		margin-bottom: 0px;
	}
	.validi-table-filter-box{
		form{
			display: flex;
			align-items: center;
			.form-group{
				margin-bottom: 0;
				.css-2b097c-container{
					width: 160px !important;
					margin-right: 15px;
					.css-yk16xz-control{
						> div {
							padding: 1px 8px;
							.css-tlfecz-indicatorContainer{
								padding-right: 0;
							}
						} 
					} 
					.css-1pahdxg-control{
						> div {
							padding: 1px 8px;
							.css-tlfecz-indicatorContainer{
								padding-right: 0;
							}
							.css-1gtu0rj-indicatorContainer{
								padding-right: 0;
							}
						} 
					}
				}
				.form-control{
					width: 280px;
					padding: 9px 12px;
				}
			}
		}
	}
}
// table css file //
.validi-table-data-wrapper{
	table{
		thead{
			tr{
				border-bottom: 1px solid $border-color;
				th{
					font-weight: 500;
					font-size: 16px;
					color: $lightgrey;
					padding: 16px 10px;
					border: none;
				}
			}
		}
		tbody{
			tr{
				border-bottom: 1px solid $border-color;
				td{
					font-weight: 600;
					font-size: 14px;
					color: $black;
					padding: 16px 10px;
					border: none;
					&.validi-date-text{
						font-weight: 400;
					}
					&.validi-mg-text{
						color: $green;
					}
					.validi-active-icons-wrapper{
						display: flex;
						i{
							width: 21px;
							height: 18px;
							display: flex;
							justify-content: center;
							align-items: center;
							margin-right: 7px;
							cursor: pointer;
							transition: all 0.3s ease-in-out;
							&:last-child{
								margin-right: 0px;
							}
							&:hover{
								opacity: 0.7;
							}
							img{
								width: 100%;
								height: 100%;
								object-fit: contain;
								object-position: center;
							}
						}
					}
				}
			}
		}
	}
}
// table css file //